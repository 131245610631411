@font-face {
  font-family: 'Roboto';
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}